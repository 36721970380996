import { useModal } from '@ebay/nice-modal-react'
import { styled } from '@linaria/react'
import { useNavigate } from '@tanstack/router'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import crypto from '../../assets/wallet-management/crypto.webp'
import spei from '../../assets/wallet-management/spei.webp'
import {
  DefaultButton,
  Spinner,
  ToastMessage,
  WebTitle,
} from '../../components/common'
import Icon from '../../components/icon'
import Status from '../../components/kyc/status'
import { WithdrawHistory } from '../../components/wallet-management'
import WithdrawButtonGroup from '../../components/withdraw-form/withdraw-button-group'
import type { OptionType } from '../../components/withdraw-form/withdraw-dropdown'
import WithdrawDropdown from '../../components/withdraw-form/withdraw-dropdown'
import useDimensions from '../../hooks/useDimensions'
import useKycInfo from '../../hooks/useKycInfo'
import useMemberInfo from '../../hooks/useMemberInfo'
import { useBoundStore } from '../../store/store'
import { type ApiResponse } from '../../types/api'
import { type WalletDetails } from '../../types/wallet-management'
import { api } from '../../utils/api-client'
import closeModal from '../../utils/closeModal'
import { numberWithCommas } from '../../utils/number'
import CryptoConverstion from './crypto-converstion'
import FillUpRfc from './fill-up-rfc'

const Form = styled.form`
  margin-top: 12px;
  @media (min-width: 1200px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0px 24px;
    margin-top: 0;
  }
`

const GroupLabel = styled.div`
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: var(--txt);
  border-bottom: 2px solid var(--highlight);
  display: inline-block;
  margin-bottom: 20px;
  @media (min-width: 1200px) {
    font-size: 16px;
  }
`

const FormFieldGroup = styled.div`
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }
  @media (min-width: 1200px) {
    &.full {
      grid-column: 1 / 3;
    }
  }
`

const AmountField = styled.input`
  color: var(--txt);
  font-size: 14px;
  font-weight: 400;
  font-family: 'Poppins';
  width: 100%;
  border-bottom: 1px solid #888;
  @media (min-width: 1200px) {
    font-size: 16px;
  }
`

const SubmitContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  @media (min-width: 1200px) {
    padding-top: 0;
    grid-column: 1 / 3;
  }
`

const AmountContainer = styled.div`
  @media (min-width: 1200px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 24px;
  }
`

const Text = styled.div`
  font-size: 16px;
  color: var(--txt);
  margin-left: 12px;
`

const Balance = styled.div`
  fill: var(--txt);
`

const FormContainer = styled.div`
  padding-bottom: 24px;
  @media (min-width: 1200px) {
    display: grid;
    grid-template-columns: 2fr 0.9fr;
    gap: 40px;
    align-items: start;
  }
`

const Help = styled.p`
  width: 194px;
  font-size: 14px;
  color: var(--secondary-txt);
  margin: 24px auto;
  text-align: center;
  button {
    color: var(--highlight);
    font-size: 14px;
    text-decoration: underline;
  }
`

const ErrorMessage = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-top: -12px;
  margin-bottom: 24px;
  color: rgb(255, 64, 88);
`

type WithdrawDetails = {
  submit_type: 'debit'
  debit_bank: OptionType | null
  amount: string
  platform: OptionType
}

const WithdrawForm = () => {
  const navigate = useNavigate()
  const { refreshMemberInfo } = useMemberInfo()
  const { kycInfo, isVerified, getKycInfo } = useKycInfo()
  const modal = useModal()
  const cryptoConversion = useModal(CryptoConverstion)
  const verif_request_comment = useBoundStore(
    state => state.user.verif_request_comment,
  )
  const { t } = useTranslation(['walletManagement', 'kyc2'])
  const channels = [
    {
      icon: spei,
      label: t('transfer-spei'),
      id: 'spei',
    },
    {
      icon: crypto,
      label: t('transfer-crypto'),
      id: 'crypto',
    },
  ]
  const withdrawType = 'bank'
  const amounts = [500, 5000, 20000, 50000]
  const toastMessage = useModal(ToastMessage)
  const fillUpRfc = useModal(FillUpRfc)
  const { isDesktop } = useDimensions()
  const balance = useBoundStore(state => state.user.unified_balance)
  const [walletList, setWalletList] = useState<WalletDetails[]>([])
  const [withdrawDetails, setWithdrawDetails] = useState<WithdrawDetails>({
    submit_type: 'debit',
    debit_bank: null,
    platform: channels[0],
    amount: '',
  })
  const [isLoading, setIsLoading] = useState(false)

  const getWalletList = async () => {
    const response = await api.post<ApiResponse<WalletDetails[]>>(
      '/ajax_data.php',
      { type: 'bank_list' },
    )
    const { status, info } = response.data

    if (status === 1) {
      if (info.length > 0) {
        setWithdrawDetails({
          submit_type: 'debit',
          debit_bank: {
            label: info[0].debit_bank,
            id: info[0].id,
            bank_type: info[0].bank_type,
          },
          platform: channels[0],
          amount: '',
        })
      }

      if (withdrawType === 'bank')
        setWalletList(
          info.filter(
            item =>
              item.bank_type !== 'USDTERC20' && item.bank_type !== 'USDTTRC20',
          ),
        )
      else if (withdrawType === 'usdt')
        setWalletList(
          info.filter(
            item =>
              item.bank_type === 'USDTERC20' || item.bank_type === 'USDTTRC20',
          ),
        )
    }
  }

  const setAmount = (amount: number) => {
    setWithdrawDetails(prev => ({ ...prev, amount: amount.toString() }))
  }

  useEffect(() => {
    getWalletList()
  }, [])

  useEffect(() => {
    if (!modal.visible) return
    getKycInfo()
    refreshMemberInfo()
  }, [modal.visible])

  const filteredWallets = useMemo(() => {
    if (withdrawDetails.platform.id === 'crypto') {
      return walletList.filter(wallet => +wallet.is_crypto)
    }
    return walletList.filter(wallet => !+wallet.is_crypto)
  }, [walletList, withdrawDetails.platform])

  const inputError = useMemo(() => {
    if (!withdrawDetails.platform) return ''
    if (withdrawDetails.platform.id === 'crypto' && +withdrawDetails.amount !== 0) {
      if (+withdrawDetails.amount < 979)
        return 'Retiro mínimo en Crypto 979 MXN'
    }
    return ''
  }, [withdrawDetails.amount, withdrawDetails.platform])


  const onSubmit = async () => {
    const amountValue = Number(withdrawDetails.amount)
    if (isNaN(amountValue) || amountValue < 100) {
      toastMessage.show({
        text: t('min-withdraw'),
        isValid: false,
      })
      return
    }

    if (Number(balance) < amountValue) {
      toastMessage.show({
        text: `${t('not-enough')} $${numberWithCommas(parseInt(balance))}`,
        isValid: false,
      })
      return
    }

    setIsLoading(true)
    const reqBody = {
      ...withdrawDetails,
      debit_bank: withdrawDetails.debit_bank?.id ?? '',
    }
    try {
      const response = await api.post<ApiResponse<string>>(
        '/center.php',
        reqBody,
      )
      const { status, info } = response.data
      if (status === 2) {
        fillUpRfc.show({
          closeWallet: () => {
            closeModal(modal)
          },
        })
      } else {
        const isValid = status === 1
        toastMessage.show({
          text: info,
          isValid,
        })
      }
    } catch (e) {
      if (e instanceof Error) {
        toastMessage.show({ text: e.message, isValid: false })
      }
    }
    setIsLoading(false)
  }

  if (kycInfo.isLoading) {
    return <Spinner containerStyle={{ paddingTop: 30 }} />
  }

  if ([2, 4].includes(+kycInfo.should_kyc_status)) {
    if (isVerified === 2) {
      return (
        <Status
          status="in-review"
          statusText={t('kyc2:in-review')}
          desc={t('kyc2:thanks-for-verifying')}
        />
      )
    }
    if (isVerified === 3) {
      return (
        <>
          <Status
            status="failed"
            statusText={t('kyc2:declined')}
            desc={verif_request_comment}
          />
          <Help>
            {t('kyc2:click')}{' '}
            <button onClick={() => window.fcWidget?.open()}>
              {t('kyc2:assistance')}
            </button>{' '}
            {t('kyc2:any-questions')}
          </Help>
          <DefaultButton
            variant="blue"
            onClick={() => {
              navigate({ to: '/profile/kyc' })
              closeModal(modal)
            }}
            width={'220px'}
            style={{ margin: '0 auto' }}>
            {t('kyc2:try-again')}
          </DefaultButton>
        </>
      )
    }
  }

  return (
    <FormContainer>
      <WebTitle />
      <Form
        onSubmit={e => {
          e.preventDefault()
          if (withdrawDetails.platform.id === 'crypto') {
            cryptoConversion.show({
              onSubmit,
              amount: withdrawDetails.amount,
              bankType: withdrawDetails.debit_bank?.bank_type ?? '',
            })
          } else {
            onSubmit()
          }
        }}>
        <FormFieldGroup style={{ marginBottom: 12 }}>
          <GroupLabel>{t('balance')}</GroupLabel>
          <Balance className="flx-str-ctr">
            <Icon id="wallet" width="22" height="23" />
            <Text>$ {numberWithCommas(Number(balance))}</Text>
          </Balance>
        </FormFieldGroup>
        <FormFieldGroup>
          <GroupLabel>{t('type-of-withdrawal')}</GroupLabel>
          <WithdrawDropdown
            label={!isDesktop ? t('withdrawal-method') : undefined}
            options={channels}
            selected={withdrawDetails.platform}
            onChange={opt => {
              setWithdrawDetails(prev => ({
                ...prev,
                platform: opt,
                debit_bank: null,
              }))
            }}
          />
        </FormFieldGroup>
        <FormFieldGroup>
          <GroupLabel>{t('withdraw-info')}</GroupLabel>
          <WithdrawDropdown
            options={filteredWallets.map(wallet => ({
              label: wallet.debit_bank,
              id: wallet.id,
              bank_type: wallet.bank_type,
            }))}
            selected={withdrawDetails.debit_bank}
            onChange={opt =>
              setWithdrawDetails(prev => ({ ...prev, debit_bank: opt }))
            }
          />
        </FormFieldGroup>
        <FormFieldGroup className="full">
          <GroupLabel>{t('amount')}</GroupLabel>
          <AmountContainer>
            <WithdrawButtonGroup
              values={amounts}
              amount={Number(withdrawDetails.amount)}
              onClick={setAmount}></WithdrawButtonGroup>
            <AmountField
              inputMode="numeric"
              placeholder="0.00"
              value={withdrawDetails.amount}
              onChange={e => {
                if (/^\d*$/.test(e.target.value)) {
                  setWithdrawDetails(prev => ({
                    ...prev,
                    amount: e.target.value,
                  }))
                }
              }}
            />
          </AmountContainer>
        </FormFieldGroup>
        {inputError && <ErrorMessage>{inputError}</ErrorMessage>}
        <SubmitContainer>
          {isLoading ? (
            <Spinner />
          ) : (
            <DefaultButton
              variant="blue"
              width={isDesktop ? 220 : 160}
              type="submit"
              disabled={
                !withdrawDetails.platform ||
                !withdrawDetails.debit_bank ||
                !Number(withdrawDetails.amount) ||
                (withdrawDetails.platform.id === 'crypto' && +withdrawDetails.amount < 979)
              }>
              {t('withdraw')}
            </DefaultButton>
          )}
        </SubmitContainer>
      </Form>
      <WithdrawHistory />
    </FormContainer>
  )
}

export default WithdrawForm
