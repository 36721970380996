import { styled } from '@linaria/react'

import { numberWithCommas } from '../../utils/number'

const Container = styled.div`
  display: flex;
  margin-bottom: 30px;
  @media (min-width: 1200px) {
    margin-bottom: 0;
  }
`

const Button = styled.button`
  background: var(--secondary);
  border-right: 1px solid var(--secondary-txt-20);
  width: 100%;
  height: 36px;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 14px;
  color: var(--txt);

  &.selected {
    background-color: var(--highlight);
    color: var(--primary-btn-txt);
  }

  &:last-child {
    border-right: 0;
  }
  @media (min-width: 1200px) {
    height: 40px;
  }
`

type WithdrawButtonGroupProps = {
  values: number[]
  amount: number
  onClick: (amount: number) => void
}

const WithdrawButtonGroup = ({
  values,
  amount,
  onClick,
}: WithdrawButtonGroupProps) => {
  return (
    <Container>
      {values.map((value, index) => (
        <Button
          key={index}
          type="button"
          className={amount === value ? 'selected' : ''}
          onClick={() => {
            onClick(value)
          }}>
          ${numberWithCommas(value)}
        </Button>
      ))}
    </Container>
  )
}

export default WithdrawButtonGroup
